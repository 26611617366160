import React from 'react'
import './Section.css'

function Footer() {

  return (
    <div className="section" id="footer">
      <p>Designed and built by Matthew Lam</p>
      <br/>
    </div>
  );
}

export default Footer;